<template>
  <div class="coinater">
    <div class="fdleft">
      <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ project.name }}</div>
        </div>
      </div>
      <div class="fdlcont">
        <div class="fdltitle">能效标识申报指标情况表</div>
        <div class="fdltxt">
          <div class="tableform">
            <el-form ref="form" :model="project" label-width="180px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目名称：">
                        {{ project.name }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="申报星级：">
                        <i
                          v-for="item in project.stars"
                          :key="item"
                          class="el-icon-star-on"
                        ></i>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-form ref="formtable" :model="formtable" label-width="180px">
            <div id="engerytable" class="engerytable">
              <table border="1" cellspacing="0" width="100%">
                <tbody>
                  <!-- <tr>
                  <th scope="col">项目名称</th>
                  <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
                </tr>
                <tr>
                  <th scope="row">申报星级</th>
                  <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
                </tr> -->
                  <tr>
                    <th scope="row">申报指标</th>
                    <td colspan="2" scope="col">
                      <input v-model="formtable.sbIndex" type="text" />
                    </td>
                    <th colspan="2" scope="col">指标情况</th>
                    <td colspan="3" scope="col">
                      <input v-model="formtable.sbCircu" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">相对节能率</th>
                    <td colspan="7" scope="col">
                      {{ formtable.relateEnergy }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">节能率</th>
                    <td colspan="2" scope="col">
                      <input v-model="formtable.energySavRate" type="text" />
                    </td>
                    <th colspan="2" scope="col">节能标准</th>
                    <td colspan="3" scope="col">
                      <div style="text-align: center">
                        <span v-if="formtable.radio == 1">50%</span>
                        <span v-if="formtable.radio == 2">65%</span>
                        <!-- <el-radio v-model="formtable.radio" label="1">75%</el-radio> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">基础项</th>
                    <td colspan="7" scope="col">
                      经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020标准要求，建筑节能率为{{
                        formtable.buildEnergyRatio
                      }}，测评合格。
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">规定项</th>
                    <td colspan="7" scope="col">
                      经测评，该项目规定项{{
                        formtable.strip
                      }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020规定要求；
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">选择项</th>
                    <td colspan="7" scope="col">
                      经测评，该项目选择项{{ formtable.strip }}条参评，加{{
                        formtable.grades
                      }}分；
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="6" scope="row">建筑热工性能</th>
                    <td>屋面保温（示例：70mm厚挤塑聚苯板）</td>
                    <td><input v-model="formtable.keepwarm" type="text" /></td>
                    <td>屋面传热系数[W/(m2·K)]（提示：保留两位小数）</td>
                    <td colspan="4">
                      <input v-model="formtable.heattransfer" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td>外墙保温（示例：80mm厚复合岩棉板）</td>
                    <td><input v-model="formtable.roofWarm" type="text" /></td>
                    <td>外墙传热系数[W/(m2·K)] （提示：保留两位小数）</td>
                    <td colspan="4">
                      <input v-model="formtable.outHeat" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">外窗型材（示例：铝合金窗（6 +16A+6））</td>
                    <td rowspan="4">
                      <input v-model="formtable.exterProfile" type="text" />
                    </td>
                    <td rowspan="2">
                      外窗传热系数[W/(m2·K)] （提示：保留两位小数，东/南/西/北）
                    </td>
                    <td>东</td>
                    <td><input v-model="formtable.outEaast" type="text" /></td>
                    <td>南</td>
                    <td><input v-model="formtable.outSouth" type="text" /></td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td><input v-model="formtable.outWest" type="text" /></td>
                    <td>北</td>
                    <td><input v-model="formtable.outNorth" type="text" /></td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      遮阳系数SC（提示：保留两位小数，东/南/西/北）
                    </td>
                    <td>东</td>
                    <td><input v-model="formtable.scEaast" type="text" /></td>
                    <td>南</td>
                    <td><input v-model="formtable.scSouth" type="text" /></td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td><input v-model="formtable.scWest" type="text" /></td>
                    <td>北</td>
                    <td><input v-model="formtable.scNorth" type="text" /></td>
                  </tr>
                  <tr>
                    <th scope="row">生活热水节能</th>
                    <td colspan="7">
                      <input
                        v-model="formtable.lifewater"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">单位建筑面积全年能耗（kWh/m2）</th>
                    <td colspan="7">
                      <input v-model="formtable.yearEnergy" type="text" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            v-model="idea"
            placeholder="请输入内容"
            style="height: 513px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <el-button type="primary" @click="pre_page">上一页</el-button>
          <el-button type="primary" @click="check">保存</el-button>
          <!-- <el-button type="primary" @click="next_page">  下一页</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkSave, projectTableInfo } from "../../../../api/project";

export default {
  data() {
    return {
      idea: "",
      pass: "",
      project_id: "",
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],
    };
  },
  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getInfo();
  },
  methods: {
    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 4,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        // that.$message.success('保存成功');
        that.$router.push({
          path: "./reportTable?project_id=" + this.project_id,
        });
      });
    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 4,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        that.formtable = that.project.detail;
        let checks = res.data.checks;
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 4) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.$nextTick(function () {
          let table = document.getElementById("engerytable");
          let inputs = table.getElementsByTagName("input");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }
        });
      });
    },
    goBack() {
      this.$router.push({
        path: "./stepInformation?id=" + this.project_id,
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea > textarea {
  height: 513px;
}
/deep/.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;

    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 30px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 34px;
      }

      .fdradio {
        margin: 20px 0;
        text-align: center;
      }

      .fdrbtn {
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}
.engerytable input {
  background-color: white;
}
</style>
